<template>
	<b-modal ref="modalDocument" static>
		<template slot="modal-header" slot-scope="{ close }">
			<h2 class="mx-auto my-auto">{{ $t('media.voir_document') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>

		<template slot="default">
		    <OfflineMedia
		    	v-if="media"
				:filename="media.media_filename"
				customClass="w-100"
			/>
        	<a v-if="document_dl_ready" ref="documentDownloadTag" href="#" hidden></a>
		</template>
		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="download()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				<font-awesome-icon v-if="!processing" :icon="['fas', 'download']" />
				{{ $t('action.telecharger') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">

	import CloudMedia from "@/mixins/CloudMedia.js"

	export default {
		name: 'ModalViewDocument',
		mixins: [CloudMedia],
		props: ['media'],
		data () {
			return {
				processing: false,
				document_dl_ready: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
			},
			
			openModal() {
				this.$refs.modalDocument.show()
			},

			async download() {
                this.document_dl_ready = true
                this.processing = true
                console.log(this.media);

				const blob = await this.$sync.loadOnDemandFile(this.media.media_filename)
				const fileURL = window.URL.createObjectURL(blob)
                const fileLink = this.$refs.documentDownloadTag

                fileLink.href = fileURL
                fileLink.setAttribute('download', this.media.media_name)

                fileLink.click()
                this.document_dl_ready = false
                this.processing = false
			}
		},

		components: {
			OfflineMedia: () => import('GroomyRoot/components/Utils/OfflineMedia')
		}
	}

</script>